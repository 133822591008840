import { T } from "@/locales";

export const rules = {
  number: [
    { required: true, message: T('请输入出库通知单号'), trigger: 'change' },
    { max: 32, message: T('超出最大长度 (32)'), trigger: 'change' },
  ],
  external_number: [
    { max: 32, message: T('超出最大长度 (32)'), trigger: 'change' },
  ],
  receiving_address: [
    { max: 256, message: T('超出最大长度 (256)'), trigger: 'change' },
  ],
  client: [
    { required: true, message: T('请选择货主'), trigger: 'change' },
  ],
  warehouse: [
    { required: true, message: T('请选择仓库'), trigger: 'change' },
  ],
  remark: [
    { max: 256, message: T('超出最大长度 (256)'), trigger: 'change' },
  ],
}