<template>
  <div>
    <a-card :title="$t('出库通知单')">
      <a-button slot="extra" type="primary" ghost @click="$router.go(-1)"> <a-icon type="left" />{{ $t("返回") }}</a-button>

      <a-spin :spinning="loading">
        <a-form-model ref="form" :model="form" :rules="rules" :label-col="{ span: 12 }" :wrapper-col="{ span: 12 }">
          <a-row>
            <a-col :span="24" style="width: 380px">
              <a-form-model-item prop="number" :label="$t('出库通知单号')">
                <a-input v-model="form.number" allowClear />
              </a-form-model-item>
            </a-col>
            <a-col :span="24" style="width: 380px">
              <a-form-model-item prop="warehouse" :label="$t('仓库')">
                <a-select v-model="form.warehouse" style="width: 100%">
                  <a-select-option v-for="item in warehouseItems" :key="item.id" :value="item.id">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <!-- <a-col :span="24" style="width: 380px">
              <a-form-model-item prop="area" :label="$t('库区')">
                <AreaSelect v-model="form.area" :warehouse="form.warehouse" :name="form.area_name" />
              </a-form-model-item>
            </a-col> -->
            <a-col :span="24" style="width: 380px">
              <a-form-model-item prop="client" :label="$t('货主')">
                <client-select v-model="form.client"></client-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="24" style="width: 380px">
              <a-form-model-item prop="counterparty" :label="$t('往来单位')">
                <CounterpartySelect v-model="form.counterparty" @select="onSelectCounterparty"></CounterpartySelect>
              </a-form-model-item>
            </a-col>
            <a-col :span="24" style="width: 380px">
              <a-form-model-item prop="order_type" :label="$t('订单类型')">
                <a-select v-model="form.order_type" style="width: 100%">
                  <a-select-option v-for="item in orderTypeItems" :key="item.id" :value="item.id">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>

            <a-col :span="24" style="width: 380px">
              <a-form-model-item prop="external_number" :label="$t('往来单位单号')">
                <a-input v-model="form.external_number" allowClear />
              </a-form-model-item>
            </a-col>

            <a-col :span="24" style="width: 380px">
              <a-form-model-item prop="customer_contact" :label="$t('联系人')">
                <a-input v-model="form.customer_contact" allowClear />
              </a-form-model-item>
            </a-col>
            <a-col :span="24" style="width: 380px">
              <a-form-model-item prop="contact_phone" :label="$t('联系人电话')">
                <a-input v-model="form.contact_phone" allowClear />
              </a-form-model-item>
            </a-col>
            <a-col :span="24" style="width: 380px">
              <a-form-model-item prop="receiving_address" :label="$t('送货地址')">
                <a-input v-model="form.receiving_address" allowClear />
              </a-form-model-item>
            </a-col>
            <!-- <a-col :span="24" style="width: 380px">
              <a-form-model-item prop="delivery_date" :label="$t('预计出库日')">
                <a-date-picker v-model="form.delivery_date" valueFormat="YYYY-MM-DD" style="width: 100%" />
              </a-form-model-item>
            </a-col> -->
            <a-col :span="24" style="width: 380px">
              <a-form-model-item prop="arrival_date" :label="$t('预计到货日')">
                <a-date-picker v-model="form.arrival_date" valueFormat="YYYY-MM-DD" style="width: 100%" />
              </a-form-model-item>
            </a-col>
            <a-col :span="24" style="width: 380px">
              <a-form-model-item prop="delivery_method" :label="$t('发货方式')">
                <a-input v-model="form.delivery_method" allowClear />
              </a-form-model-item>
            </a-col>
            <a-col :span="24" style="width: 380px">
              <a-form-model-item prop="payment_method" :label="$t('付款方式')">
                <a-input v-model="form.payment_method" allowClear />
              </a-form-model-item>
            </a-col>
            <!-- <a-col :span="24" style="width: 380px">
              <a-form-model-item prop="express" :label="$t('快递')">
                <a-input v-model="form.express" allowClear />
              </a-form-model-item>
            </a-col> -->
            <!-- <a-col :span="24" style="width: 380px">
              <a-form-model-item prop="packaging_requirements" :label="$t('包装要求')">
                <a-input v-model="form.packaging_requirements" allowClear />
              </a-form-model-item>
            </a-col> -->
            <!-- <a-col :span="24" style="width: 380px">
              <a-form-model-item prop="delivery_time" :label="$t('送货时间')">
                <a-date-picker v-model="form.delivery_time" valueFormat="YYYY-MM-DD" style="width: 100%" />
              </a-form-model-item>
            </a-col> -->

            <a-col :span="24" style="width: 380px">
              <a-form-model-item prop="remark" :label="$t('备注')">
                <a-input v-model="form.remark" allowClear />
              </a-form-model-item>
            </a-col>
            <a-col :span="24" style="width: 380px">
              <a-form-model-item prop="delivery_files" :label="$t('附件')">
                <delivery-file-upload v-model="form.delivery_files" />
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-form-model>

        <a-divider></a-divider>

        <div>
          <a-row gutter="16">
            <a-col :span="24" :md="8" :xl="6" style="width: 256px">
              <a-input
                v-model="materialNumber"
                :placeholder="$t('请扫描产品条码')"
                @keyup.enter.native="scanMaterial"
                allowClear
              />
            </a-col>
            <a-space>
              <a-button type="primary" @click="openInventoryModal">{{ $t("选择产品") }}</a-button>
              <a-button type="default" @click="batchAutoPicking">{{ $t("自动拣配") }}</a-button>
            </a-space>
          </a-row>

          <div style="margin-top: 16px">
            <a-table
              size="small"
              :loading="tableLoading"
              :columns="curColumns"
              :data-source="deliveryMaterialItems"
              :pagination="false"
              :scroll="{ x: 1720 }"
              :rowSelection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
            >
              <div slot="status" slot-scope="value, item">
                <a-select v-model="item.status" size="small" style="width: 100%" @change="item.batch = undefined">
                  <a-select-option value="qualified">{{ $t("良品") }}</a-select-option>
                  <a-select-option value="unqualified"> {{ $t("不良品") }} </a-select-option>
                </a-select>
              </div>
              <div slot="batch" slot-scope="value, item">
                <BatchSearchSelect
                  v-model="item.batch"
                  :placeholder="$t('批次')"
                  :warehouse="form.warehouse"
                  :material="item.material"
                  :client="form.client"
                  :status="item.status"
                  size="small"
                  :defaultItem="item"
                />
              </div>
              <div slot="total_quantity" slot-scope="value, item">
                <a-input-number v-model="item.total_quantity" size="small"></a-input-number>
              </div>
              <div slot="weight" slot-scope="value, item">
                <span v-if="item.total_quantity && item.material_weight">
                  {{ NP.times(item.total_quantity, item.material_weight || 0, 0.001) }}
                </span>
              </div>
              <div slot="volume" slot-scope="value, item">
                <span v-if="item.total_quantity && item.material_volume">
                  {{ NP.times(item.total_quantity, item.material_volume || 0) }}
                </span>
              </div>
              <div slot="remark" slot-scope="value, item, index">
                <a-input v-model="item.remark" size="small"></a-input>
              </div>
              <div slot="action" slot-scope="value, item, index">
                <a-button-group size="small">
                  <a-button type="danger" @click="removeMaterial(item, index)">{{ $t("删除") }}</a-button>
                  <a-button type="primary" @click="copyMaterial(item, index)">{{ $t("复制") }}</a-button>
                  <a-button @click="autoPicking(item, index)">{{ $t("自动拣配") }}</a-button>
                </a-button-group>
              </div>
            </a-table>
          </div>
        </div>
      </a-spin>

      <div style="margin-top: 32px">
        <a-popconfirm :title="$t('确定创建吗?')" @confirm="create">
          <a-button type="primary" :loading="loading">{{ $t("创建") }}</a-button>
        </a-popconfirm>
      </div>
    </a-card>

    <inventory-select-modal
      v-model="materialModalVisible"
      :warehouse="form.warehouse"
      :client="form.client"
      @select="onSelectMaterial"
      @confirm="onConfirm"
    >
    </inventory-select-modal>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { orderTypeOption, warehouseOption, inventoryOption } from "@/api/option";
import { deliveryOrderCreate } from "@/api/stockOut";
import { getDeliveryNumber } from "@/api/data";
import { columns } from "./columns";
import { rules } from "./rules";
import moment from "moment";
import NP from "number-precision";
import { batchOption } from "@/api/option";
import { deliveryDetail, deliveryMaterials } from "@/api/stockOut";

export default {
  components: {
    ClientSelect: () => import("@/components/ClientSelect/"),
    CounterpartySelect: () => import("@/components/CounterpartySelect/"),
    // AreaSelect: () => import("@/components/AreaSelect/"),
    DeliveryFileUpload: () => import("@/components/DeliveryFileUpload/index"),
    InventorySelectModal: () => import("@/components/InventorySelectModal/index"),
    BatchSearchSelect: () => import("@/components/BatchSearchSelect/index"),
  },
  inject: ["reloadPage"],
  computed: {
    ...mapState({
      curWarehouse: (state) => state.user.currentWarehouse,
    }),
    enableQualityInspection() {
      return this.$store.state.user.enableQualityInspection;
    },
    curColumns() {
      const items = [];
      for (const item of columns) {
        if (!this.enableQualityInspection && item.key === "quality_inspection_status") {
          continue;
        }

        items.push(item);
      }
      return items;
    },
    defaultWarehouse() {
      return this.$store.state.user.defaultWarehouse;
    },
    defaultClient() {
      return this.$store.state.user.defaultClient;
    },
  },
  data() {
    return {
      NP,
      rules,
      moment,
      loading: false,
      tableLoading: false,
      form: { number: "", customer_contact: undefined, receiving_address: undefined, contact_phone: undefined },
      deliveryMaterialItems: [],
      orderTypeItems: [],

      clientItems: [],
      warehouseItems: [],
      materialNumber: "",
      materialModalVisible: false,

      selectedRowKeys: [],
    };
  },
  methods: {
    initData() {
      this.resetForm();
      let deliveryOrder = this.$route.query.id;
      if (deliveryOrder) {
        deliveryDetail({ id: deliveryOrder }).then((data) => {
          this.form = data;
          getDeliveryNumber().then((data) => {
            this.form.number = data.number;
          });
        });

        deliveryMaterials({ id: deliveryOrder }).then((data) => {
          this.deliveryMaterialItems = data;
        });
      } else {
        getDeliveryNumber().then((data) => {
          this.form.number = data.number;
        });
      }

      warehouseOption({ page_size: 999999 }).then((data) => {
        this.warehouseItems = data.results;
      });

      orderTypeOption({ page_size: 999999, type: "outbound" }).then((data) => {
        this.orderTypeItems = data.results;
      });
    },
    onConfirm(items) {
      for (const item of items) {
        this.onSelectMaterial(item);
      }
    },
    onSelectMaterial(item) {
      this.deliveryMaterialItems.splice(0, 0, {
        id: item.material,
        material: item.material,
        material_name: item.material_name,
        material_number: item.material_number,
        total_quantity: 1,
        unit: item.unit,
        material_volume: item.material_volume,
        material_weight: item.material_weight,
        material_spec: item.material_spec,
        status: "qualified",
        remark: "",
        available_quantity: item.available_quantity,
      });
    },
    onSelectCounterparty(item) {
      this.form = {
        ...this.form,
        customer_contact: item.contacts,
        receiving_address: item.contacts,
        contact_phone: item.phone,
      };
    },
    create() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.deliveryMaterialItems.length == 0) {
            this.$message.warn(this.$t("未添加产品"));
            return;
          }

          for (let item of this.deliveryMaterialItems) {
            if (!item.batch) {
              this.$message.warn(this.$t("未选择批次"));
              return;
            }
          }

          let formData = { ...this.form, delivery_material_items: this.deliveryMaterialItems };
          this.loading = true;
          deliveryOrderCreate(formData)
            .then((data) => {
              this.$message.success(this.$t("创建成功"));
              this.$router.go(-1);
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
    resetForm() {
      this.form = {
        number: "",
        warehouse: this.defaultWarehouse,
        client: this.defaultClient,
      };
      this.deliveryMaterialItems = [];
    },
    scanMaterial() {
      if (!this.materialNumber) {
        this.$message.warning(this.$t("请扫描产品条码"));
        return false;
      }
      if (!this.form.warehouse) {
        this.$message.warn(this.$t("请选择仓库"));
        return;
      }
      if (!this.form.client) {
        this.$message.warn(this.$t("请选择货主"));
        return;
      }

      this.tableLoading = true;
      inventoryOption({ search: this.materialNumber, warehouse: this.form.warehouse, client: this.form.client })
        .then((data) => {
          if (!data.results.length) {
            this.$message.warning(this.$t("没有找到相关产品, 请确认扫描的产品编号！"));
            return false;
          }
          let item = data.results[0];

          this.deliveryMaterialItems.splice(0, 0, {
            id: item.material,
            material: item.material,
            material_name: item.material_name,
            material_number: item.material_number,
            total_quantity: 1,
            unit: item.unit,
            material_volume: item.material_volume,
            material_weight: item.material_weight,
            material_spec: item.material_spec,
            status: "qualified",
            remark: "",
            available_quantity: item.available_quantity,
          });
        })
        .finally(() => {
          this.tableLoading = false;
        });
    },
    openInventoryModal() {
      if (!this.form.warehouse) {
        this.$message.warn(this.$t("请选择仓库"));
        return;
      }
      if (!this.form.client) {
        this.$message.warn(this.$t("请选择货主"));
        return;
      }
      this.materialModalVisible = true;
    },
    removeMaterial(item, index) {
      this.deliveryMaterialItems.splice(index, 1);
    },
    copyMaterial(item, index) {
      this.deliveryMaterialItems.splice(index, 0, { ...item });
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
      console.log(selectedRowKeys);
    },
    batchAutoPicking() {
      if (!this.selectedRowKeys.length) {
        this.$message.warning(this.$t("请选择要自动拣配的产品!"));
        return false;
      }

      for (let index of this.selectedRowKeys) {
        const item = this.deliveryMaterialItems[index];

        if (item.total_quantity) {
          this.autoPicking(item, index);
        }
      }
    },
    autoPicking(item, index) {
      this.tableLoading = true;
      let searchForm = {
        page: 1,
        page_size: 999999,
        has_stock: true,
        warehouse: this.form.warehouse,
        client: this.form.client,
        material: item.material,
      };
      batchOption(searchForm)
        .then((data) => {
          const materialItems = [];
          let totalQuantity = item.total_quantity;

          for (let batchItem of data.results) {
            let remainQuantity;
            if (item.status === "unqualified") {
              remainQuantity = batchItem.unqualified_quantity;
            } else {
              remainQuantity = batchItem.qualified_quantity;
            }

            if (remainQuantity <= 0) {
              continue;
            }

            if (totalQuantity > remainQuantity) {
              materialItems.push({
                ...item,
                batch: batchItem.id,
                batch_number: batchItem.number,
                total_quantity: remainQuantity,
              });

              totalQuantity -= remainQuantity;
            } else {
              materialItems.push({
                ...item,
                batch: batchItem.id,
                batch_number: batchItem.number,
                total_quantity: totalQuantity,
              });
              totalQuantity = 0;

              break;
            }
          }

          if (totalQuantity > 0) {
            materialItems.push({
              ...item,
              total_quantity: totalQuantity,
            });
            this.$message.warning(`产品[${item.material_name}] 无库存分配`);
          }

          if (materialItems.length > 0) {
            this.deliveryMaterialItems.splice(index, 1, ...materialItems);
          } else {
            this.$message.warning(`产品[${item.material_name}] 无库存分配`);
          }
        })
        .finally(() => {
          this.tableLoading = false;
        });
    },
  },
  mounted() {
    this.initData();
  },
};
</script>
